import React from 'react'
import { DatainsightsecStyles } from './Datainsightsec.styles'
import Image from 'components/ImgOptimized'

function Datainsightsec(props) {
  const { datainsightSecProps } = props.datainsightsecdata
  const {
    data: {
      splashImageFull: {
        childImageSharp: { fluid },
      },
    },
  } = props
  return (
    <DatainsightsecStyles>
      <div className="container">
        <div className="content-block">
          <h3>{datainsightSecProps.heading}</h3>
          <div className="listingdata-blk">
            {datainsightSecProps.singleDataProps.map((singleDataProp, i) => {
              return (
                <div key={i} className="singledata-blk">
                  <div className="icon">
                    <img src={singleDataProp.icon} alt="" />
                  </div>
                  <p>{singleDataProp.content}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="img-blk">
        <Image
          fluid={fluid}
          lazyload={{
            enabled: true,
            once: true,
            offset: 1000,
            heigth: 792,
          }}
        />
      </div>
    </DatainsightsecStyles>
  )
}

export default Datainsightsec
