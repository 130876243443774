import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'
import { images } from 'config/images'

export const MobilesolutionsStyles = styled.div`
  .heading {
    margin-bottom: 74px;
    ${xl(`   
        margin-bottom: 60px;
    `)} 
    ${lg(`   
        margin-bottom: 50px;
    `)} 
    ${md(`   
        margin-bottom: 40px;
    `)} 
  }
  .solution-listng-block {
    display: flex;
    justify-content: space-between;
    ${md(`   
        flex-direction: column;
    `)} 
    .single-blk {
      max-width: 294px;
      width: 100%;
      ${lg(`   
        max-width: 200px;
      `)} 
       ${md(`   
        max-width: 100%;
        margin-bottom:30px;
      `)} 
      &:last-child {
        ${md(`   
          margin-bottom:0;
        `)} 
      }
      .img-blk {
        position: relative;
        margin-bottom: 34px;
        .img-content {
          width: calc(100% - 40px);
          height: 254px;
          position: relative;
          margin: 0 auto;
          margin-top: 20px;
          background: #ffdcf9;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 54px;
          ${lg(`   
            width: calc(100% - 15px);
            margin-top: 15px;
            height: 172px;
            padding:20px;
            margin-bottom: 36px;
          `)} 
          ${md(`   
              margin-bottom: 34px;
              height: 252px;
          `)} 
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            top: -20px;
            right: -20px;
            position: absolute;
            background: #ffdcf9;
            opacity: 0.3;
            z-index: -1;
            ${lg(`   
            top: -15px;
            right: -15px;
          `)} 
          }
          &:after {
            content: '';
            width: 100%;
            height: 100%;
            bottom: -20px;
            left: -20px;
            position: absolute;
            background: #ffdcf9;
            opacity: 0.2;
            z-index: -1;
            ${lg(`   
            bottom: -15px;
            left: -15px;
          `)} 
          }
          img {
            transform:scale(0.7);
            ${md(`   
            transform:scale(0.9);
          `)} 
          }
        }
      }
      .text-caption {
        h3 {
          margin-bottom: 13px;
          ${xl(`
            font-size: 26px;
            line-height: 32px;
          `)}
          ${lg(`
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 9px;
          `)}
          ${md(`
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 9px;
          `)}
          
        }
        p {
          margin: 0;
          padding-right: 20px;
          ${lg(`
            font-size: 18px;
            line-height: 28px;
          `)}
          ${md(`
            font-size: 16px;
            line-height: 25px;
            padding-right: 0;
          `)}
          .read-more-button{
            display: inline-block;
            width: 24px;
            height: 22px;
            font-size: 0;
            background: url(${images.rrightArrow}) no-repeat 50% 50%;
            margin-left: 15px;
          }
        }
      }
    }
  }
`
