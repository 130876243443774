import React from 'react'
import { images } from 'config/images'

export const SwiftShopperProps = {
  bannerProps: {
    heading: 'Swift Shopper',
    bannerPara:
      'Helping retailers bring modern shopping experience through faster checkouts and customer analytics',
    BannerAlt: 'Swift Shopper Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara:
      'While e-commerce is the fastest growing segment in retail, brick and mortar sales still are 8 times more than online sales. Swift shopper is solving the multi-billion dollar problem of faster checkout times, customer loyalty programs, and deeper analytics into buyer behavior. With deeper partnerships with retailers and top POS providers, Swift Shopper is targeting a sizable market of over 12,000 retail stores in the US alone.',
    subheading: 'Bringing future of retail shopping to brick and mortar',
    paraLeft:
      'Swift Shopper wants to help bring the future of offline shopping experiences to brick and mortar retailers. Having raised over $10 million in funding, they are chasing the ultimate goal of reduce checkout times for customers - ultimately improving customer experience. ',
    paraRight:
      'Their platform helps retailers improve shoppers’ experience by providing an easy-to-use mobile app that provides a personalized shopping solution. With Swift Shopper’s mobile shopping solution, shoppers can cut checkout times by 80%.',
  },
  PartnerProfileProps: {
    subTitle: 'Partner Profile',
    title: 'Swift Shopper',
    logos: [
      {
        src: images.iosLogo,
      },
      {
        src: images.androidLogo,
      },
      {
        src: images.nodeLogo,
      },
      {
        src: images.awsglueeltLogo,
      },
    ],
    statisticProps: [
      {
        count: '2012',
        title: 'Founded',
      },
      {
        count: '120',
        title: 'affiliated stores',
      },
      {
        count: '$10M',
        title: 'Funding',
      },
      {
        count: 'USA',
        title: 'Location',
      },
    ],
  },
  caseIntegrateProps: {
    heading: 'Integrating with Swift Shopper PMO',
    para:
      'Swift Shopper reached out to see how we can help extend their tech capacity as they liked our dedicated teams offering and saw it as an ideal fit for them.',
    imgCaption: 'Agile methodology for Product development',
    secondPara:
      'Our team worked with Swift Shopper’s CTO and PMO to onboard 11 engineers. The first two weeks were spent adopting Agile processes, code review, and refactoring best practices. Sprint planning, daily standups, weekly reviews, and monthly retrospectives helped integrate our team. Regular team visits also helped engineers understand Swift Shoppers vision and culture better.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “We feel like the Simform is a part of our Swift Shopper team.
          </span>{' '}
          <br />
          They care about the quality of the work that they provide us
          <br />
          because they want to see us succeed.”
        </>
      ),
      userImg: images.lennyperkins,
      userImg2x: images.lennyperkins2x,
      userName: 'Lenny Perkins',
      designation: 'VP of Engineering',
    },
  },
  casereSultsecProps: {
    heading: 'Result',
    bottomTitle: 'Design',
    bottomContent: 'Easily accommodating various branding guidelines',
    maincontent: (
      <>
        With the introduction of Amazon Go, the future of brick and mortar
        retail is being redefined. Swift Shopper is bringing this future of
        retail to small to medium sized stores with its cost effective solution
        and a large network of partners. <br />
        <br />
        Swift Shopper allows retailers and POS vendors to create their own
        tailored white label solution within hours. Without much upfront
        investment, retailers get access to tech that offers cashierless
        checkout experiences, deeper insights into shopper/customer habits,
        creates loyalty programs, offers tailored deals, and offers personalized
        shopping experiences.
      </>
    ),
    animationvideoURL: images.swifttVideo,
  },
  mobilesolutionsProps: {
    heading: 'Mobile solution seamlessly merges offline and online shopping',
    para:
      'For most customers, shopping starts with thinking about what to buy and creating a shopping list of items. With Swift Shopper app, users create shopping lists easily based on their location, preferences, and seasons.',
    singlesolutionList: [
      {
        img: images.planShop,
        title: (
          <>
            Plan shopping <br />
            easily
          </>
        ),
        para:
          'Shoppers scan items while shopping and avoid wait lines by checking out from the app. This FlyBy and FlyThrough checkout reduces checkout times by 80%.',
      },
      {
        img: images.accessMatch,
        title: 'Access matching deals',
        para:
          'Many shoppers decide to shop when they are driving home. SwiftShopper allows users to search stores close by. Users can check in to see available deals in a store as well as check availability of items to match their shopping list.',
      },
      {
        img: images.loyaltyProgram,
        title: 'Customized loyalty programs',
        para:
          'Swift Shopper also combines m-commerce with offline retail to create seamless shopping experience. Retailers can customize loyalty programs in the white labelled solution to drive same store sales.',
      },
    ],
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Our business is constantly changing and evolving and Simform
            continually helps us meet needs of the market.
          </span>{' '}
          Because of the
          <br />
          excellent quality of work, we have had many successful pilots.”
        </>
      ),
      userImg: images.kathryn,
      userImg2x: images.kathryn2x,
      userName: 'Kathryn Lasater',
      designation: 'CEO',
    },
  },
  twocolcontentProps: {
    heading: 'A system architecture designed to be scalable and flexible',
    maintitle:
      'The APIs were designed for quick consumption and easy integration.',
    mainpara: (
      <>
        Behind an easy to use mobile app there is a powerful tech platform
        driving it all. Integrating different retailers and POS vendors requires
        a system architecture that is flexible and quick to modify. From the
        beginning, the system was designed for retailers to easily connect their
        store’s inventory, POS, and sync their data. <br />
        <br />
        Features like frictionless checkout where users scan barcode, add items
        to cart, and checkout without waiting in line requires searching through
        millions of products and check with retailers data. All this should
        happen in split second. Now, consider that thousands of users are doing
        this at the same time in hundreds of different stores. The true
        multi-tenant SaaS design means customers can be up and running in weeks.{' '}
        <br />
        <br />
        When designing systems like this we have to make sure the platform is
        open enough to easily connect and integrate with other systems of
        retailers. We broke away from monolithic architecture to a system design
        that breaks platform into smaller decoupled services. The APIs were
        designed for quick consumption and easy integration.
      </>
    ),
    mainImgcaption:
      'Microservices based architecture to reduce dependencies and increase performance of the system',
    subheading: 'Tech Architecture',
    subheadingtitle:
      'Breaking traditional architecture into small micro-services which are independent from each other',
    subpara:
      'We developed a database architecture to enable a cloud-based data warehousing and ETL for scalable syncing between Swift Shopper and retailers’ data. We batched data using complex, multi-stage extract, transform, and load (ETL) jobs.',
    subImgcaption: 'ETL jobs running on AWS Glue environment',
  },
  shoppingExpSecProps: {
    heading:
      'Integrations to help retailers provide tailored shopping experience',
    topPara: (
      <>
        For retailers, the ability to provide tailored shopping experience with
        their branding and their workflows is the most valuable. Thus, system
        had to be flexible scalable enough to adopt new feature needs from
        different retailers.
        <br />
        <br />
        Retailers integrate their existing POS system and data in simple steps
        from the web application. They can also set their brand guidelines from
        there to customize mobile solution according to their branding style.
        Front-end architecture of all mobile and web apps were designed to
        provide performance and easy customization from backend itself.
      </>
    ),
    bottomPara:
      'Retailers can also integrate Swift Shopper SDK to their existing mobile apps to utilize all or some of the features of Swift Shopper platform. The mobile SDK also works nicely with POS when checking out allowing retailers to offer frictionless checkout experience within any kind of app.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “I’ve really got to give credit to the teams that are working with
            us, Simform is not only a backbone to our team, it is a backbone to
            our business.
          </span>{' '}
          We can better manage business because of Simform. Looking back, I
          can’t imagine us developing significant pieces of our solution without
          them.”
        </>
      ),
      userImg: images.lennyperkins,
      userImg2x: images.lennyperkins2x,
      userName: 'Lenny Perkins',
      designation: 'VP of Engineering',
    },
  },
  casecontentfullwProps: {
    heading: 'Cloud delivery to speed up the time-to-market',
    para: (
      <>
        After evaluating a variety of options, Swift Shopper team chose to base
        their application on AWS. As an AWS Advanced Partner, we designed the
        new system around the share-nothing distributed architecture to improve
        load balancing at the web and application tiers. <br />
        <br />
        The system is designed so that each layer provides scalability and high
        availability, and all computing-intensive functions and operational data
        are served through multiple nodes of the distributed in-memory
        operational database rather than having to query the back-office system.
      </>
    ),
    imgCaption:
      'Swift Shopper tech architecture with AWS services and 3rd party components',
    bottomPara:
      'A flexible, cloud-based architecture has allowed Swift Shopper to plug in new services and technologies quickly. Combined with a continuous delivery approach, this significantly improves Swift Shopper’s speed to market. They now have a shopping management solution which allows Swift Shopper to offer extensive order processing capability. New features can be added to their mobile app in days or weeks instead of months or years.',
  },
  datainsightSecProps: {
    heading: 'Data insights to help retailers make better decisions',
    singleDataProps: [
      {
        icon: images.revenue,
        content:
          'While mobile solution is designed to modernize shopper experiences resulting in better engagement, the biggest benefit retailers get from Swift Shopper platform is in directly increasing revenue per store.',
      },
      {
        icon: images.mdata,
        content:
          'Swift shopper collects millions of data points to provide deeper insights into shopping habits and brand affinities based on time, season, location, demographics, shopping history, and wish list of shoppers.',
      },
      {
        icon: images.sanalytics,
        content:
          'Swift Shopper analytics enable retailers to gain a new level of market intelligence, only possible for large scale retail operations, serving an industry that continues to wrestle with shorter product cycles and more informed online consumers.',
      },
      {
        icon: images.sdeal,
        content:
          'Retailers can create targeted deals and loyalty campaigns around multitudes of above customer behavior points. With high performance, scale-out solution stock currently processes more than 1,000,000 data points on over 200,000 products gathered from hundreds of stores. ',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'FreeWire',
        para:
          'Building EV charging platform to revolutionize the way we power everything.',
        link: 'case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        title: 'Stubbs & Woottons',
        para: 'Helping luxury shoe brand go digital',
        className: 'duck-egg-blue',
        link: 'case-studies/stubbs-woottons/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
