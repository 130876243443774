import styled from 'styled-components'
import { bodyFont, xl, lg, md, xll } from 'config/variables'

export const DatainsightsecStyles = styled.div`
  background: linear-gradient(to bottom, #eaffe4, #f8fff6 54%, #eaffe4);
  padding: 45px 0 0;
  position: relative;
  margin-bottom: 126px;
  ${xll(`
      overflow: hidden;
    `)}
    ${xl(`
      margin-bottom: 100px;
    `)}
    ${lg(`
      margin-bottom: 90px;
    `)}
    ${md(`
      padding: 40px 0 0;
      margin-bottom: 40px;
    `)}
  .content-block {
    max-width: 780px;
    width: 100%;
    ${xll(`
      max-width: 700px;
    `)}
    ${xl(`
      max-width: 100%;
    `)}
    ${lg(`
      max-width: 100%;
    `)}
    h3 {
      font-family: ${bodyFont};
      margin-bottom: 43px;
      font-weight: 500;
      ${md(`
      margin-bottom: 30px;
    `)}
    }
    .listingdata-blk {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      ${xl(`
        margin:0 -15px; 
      `)}
       ${lg(`
        margin:0 -15px; 
      `)}
       ${md(`
        margin:0; 
      `)}
      .singledata-blk {
        max-width: 350px;
        width: 100%;
        margin-bottom: 50px;
        ${xll(`
          padding-right:20px;
        `)}
        ${xl(`
          max-width: 50%;
          padding:0 15px;
        `)}
         ${lg(`
          max-width: 50%;
          padding:0 15px;
        `)}
         ${md(`
          max-width: 100%;
          padding:0;
          margin-bottom: 30px;
        `)}
        .icon {
          margin-bottom: 11px;
        }
        p {
          font-size: 18px;
          line-height: 28px;
          margin: 0;
        }
      }
    }
  }
  .img-blk {
    position: absolute;
    right: 0;
    top: 0;
    width: 533px;
    margin: -17px 0 -19px;
    ${xll(`
      height:100%;
      transform: scale(1.1);
      margin:0;
    `)}
    ${xl(`
      display:none;
    `)}
    ${lg(`
      display:none;
    `)}
    }
  }
`
