import React from 'react'
import { MobilesolutionsStyles } from './Mobilesolutions.styles'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import ReadMoreReact from 'read-more-react'

function Mobilesolutions(props) {
  const { mobilesolutionsProps } = props.mobilesolutiondata
  const caseQuoteProp = mobilesolutionsProps.caseQuoteProps
  return (
    <MobilesolutionsStyles>
      <div className="container">
        <div className="heading">
          <h2>{mobilesolutionsProps.heading}</h2>
          <p>{mobilesolutionsProps.para}</p>
        </div>
        <div className="solution-listng-block">
          {mobilesolutionsProps.singlesolutionList.map(
            (singlesolutionprop, i) => {
              return (
                <div key={i} className="single-blk">
                  <div className="img-blk">
                    <div className="img-content">
                      <img src={singlesolutionprop.img} alt="" />
                    </div>
                  </div>
                  <div className="text-caption">
                    <h3>{singlesolutionprop.title}</h3>
                    <p>
                      <ReadMoreReact
                        ideal={145}
                        min={145}
                        text={singlesolutionprop.para}
                      />
                    </p>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </MobilesolutionsStyles>
  )
}

export default Mobilesolutions
